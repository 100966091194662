<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <h3>Filters</h3>
      <div class="d-flex" style="gap: 10px">
        <!-- <div
                  style="height: 30px"
                  class="
                    border
                    d-flex
                    justify-content-center
                    align-items-center
                    cursor-pointer
                    px-2
                    rounded-sm
                    shadow-sm
                    bg-primary
                    text-white
                  "
                  @click="resetFilters"
                >
                  Reset Filters
                </div> -->
        <div
          v-if="!showFilters"
          class="
            border
            d-flex
            justify-content-center
            align-items-center
            rounded-pill
            cursor-pointer
            shadow-sm
            bg-primary
          "
          style="width: 30px; height: 30px; margin-right: 5px"
          @click="showFilters = true"
        >
          <feather-icon icon="FilterIcon" style="stroke: #fff" />
        </div>
        <div
          v-if="showFilters"
          class="
            border
            d-flex
            justify-content-center
            align-items-center
            rounded-pill
            cursor-pointer
            shadow-sm
            bg-danger
          "
          style="width: 30px; height: 30px; margin-right: 5px"
          @click="showFilters = false"
        >
          <feather-icon icon="XIcon" style="stroke: #fff" />
        </div>
      </div>
    </div>
    <div v-if="showFilters" class="courses-filter my-2">
      <b-row>
        <!-- Search Input -->
        <b-col cols="9" class="mb-2">
          <b-form-input
            v-model="filters.searchQuery"
            placeholder="Search..."
            @input="applyFilters"
            class="shadow shadow-md"
          />
        </b-col>
        <!-- Stem Filter -->
        <b-col cols="3" class="">
          <b-form-select v-model="filters.stem_course" @change="applyFilters">
            <b-form-select-option value="" disabled>STEM</b-form-select-option>
            <b-form-select-option
              v-for="(option, index) in tss_options"
              :key="index"
              :value="option.value"
            >
              {{ option.label }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <!-- Level Filter -->
        <b-col cols="3">
          <v-select
            v-model="filters.selectedLevel"
            :options="courseLevels"
            :reduce="(level) => level.course_level_id"
            label="course_level_name"
            @input="applyFilters"
            placeholder="All Levels"
            :multiple="true"
            :clearable="true"
          />
        </b-col>

        <!-- Category Filter -->
        <b-col cols="3">
          <v-select
            v-model="filters.selectedCategory"
            :options="courseCategories"
            :reduce="(category) => category.course_category_id"
            label="course_category_name"
            @input="applyFilters"
            placeholder="All Categories"
            :clearable="true"
            :multiple="true"
          />
        </b-col>

        <!-- Budget Filter -->
        <b-col cols="3">
          <b-form-select
            v-model="filters.selectedBudget"
            @change="applyFilters"
          >
            <b-form-select-option :value="filters.selectedBudget && filters.selectedBudget.length === 0">Tuition Fees</b-form-select-option>
            <b-form-select-option
              v-for="(budget, index) in tuitionFees"
              :key="index"
              :value="budget.value"
            >
              {{ budget.name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>

        <b-col cols="3" class="">
          <b-form-select v-model="filters.ielts_wavier" @change="applyFilters">
            <b-form-select-option value="" disabled
              >IELTS Wavier</b-form-select-option
            >

            <b-form-select-option
              v-for="(option, index) in tss_options"
              :key="index"
              :value="option.value"
            >
              {{ option.label }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
    </div>

    <div class="course-collapse">
      <app-collapse>
        <div
          v-for="(course, index) in filteredCourses"
          :key="index + '_course'"
        >
          <app-collapse-item title="Title" :ref="'course_' + course.course_id">
            <template #header>
              <Course
                :id="'course_' + course.course_id"
                :course="course"
                @reload-list="
                  (e) => {
                    $emit('reload-details');
                  }
                "
                @apply-course="
                  (e) => {
                    $emit('apply-course', e);
                  }
                "
                @apply-course-for-student="
                  (e) => {
                    $emit('apply-course-for-student', e);
                  }
                "
                @recommend-course="
                  (e) => {
                    $emit('recommend-course', e);
                  }
                "
                :courseCollapse="true"
              />
            </template>
            <Course
              :course="course"
              @apply-course-for-student="
                (e) => {
                  $emit('apply-course-for-student', e);
                }
              "
              @recommend-course="
                (e) => {
                  $emit('recommend-course', e);
                }
              "
              :courseDetail="true"
            />
          </app-collapse-item>
        </div>
      </app-collapse>
    </div>
  </div>
</template>
<script>
import Course from "./Course";
import {
  BFormInput,
  BRow,
  BCol,
  BButton,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BImg,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Multiselect from "vue-multiselect";
import Ripple from "vue-ripple-directive";
import MultiStateSwitch from "@/views/components/MultiStateSwitch.vue";
import vSelect from "vue-select";

export default {
  name: "UniversityCourseDetail",
  components: {
    BFormInput,
    BRow,
    BCol,
    Course,
    BButton,
    AppCollapse,
    AppCollapseItem,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg,
    BFormSelect,
    BFormSelectOption,
    Multiselect,
    MultiStateSwitch,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    courses: {
      type: Array,
      default: [],
    },
    universityDetail: {
      type: Boolean,
      default: true,
    },
    collapseCourse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      courseList: this.$props.courses,
      filters: {
        searchQuery: "",
        selectedLevel: null,
        selectedCategory: null,
        selectedBudget: null,
        stem_course: null,
        ielts_wavier: null,
      },
      filteredCourses: [],
      tss_options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Yes",
          value: "Y",
        },
        {
          label: "No",
          value: "N",
        },
      ],
      showFilters: false,
    };
  },
  computed: {
    courseLevels() {
      return this.$store.getters["home/getCourseLevels"];
    },
    courseCategories() {
      return this.$store.getters["home/getCourseCategories"];
    },
    tuitionFees() {
      return this.$store.getters["home/getTuitionFeesFilterValues"];
    },
  },
  watch: {
    courses() {
      this.onSelect();
    },
  },
  methods: {
    // onSelect() {
    //   const courses = this.$props.courses;
    //   this.courseList = courses.filter((course) => {
    //     let levelFlag = true;
    //     let categoryFlag = true;
    //     if (this.selectedBudget) {
    //       if (course.course_category_id === this.selectedBudget) {
    //         levelFlag = true;
    //       } else {
    //         levelFlag = false;
    //       }
    //     }
    //     if (this.selectedLevel) {
    //       if (course.course_level_id === this.selectedLevel) {
    //         categoryFlag = true;
    //       } else {
    //         categoryFlag = false;
    //       }
    //     }
    //     return levelFlag && categoryFlag;
    //   });
    // },
    setFilters() {
      this.filters.selectedLevel = this.filters.course_level_ids;
      this.filters.selectedCategory = this.filters.course_category_ids;
      this.filters.selectedBudget = this.filters.tuition_fees[0];
      this.filters.stem_course = this.filters.stem_course;
      this.filters.ielts_wavier = this.filters.ielts_wavier;
    },
    applyFilters() {
      this.filteredCourses = this.courseList.filter((course) => {
        const { searchQuery, selectedLevel, selectedCategory, selectedBudget } =
          this.filters;

        const matchesSearch = searchQuery
          ? course.course_name.toLowerCase().includes(searchQuery.toLowerCase())
          : true;

        const matchesLevel =
          selectedLevel && selectedLevel.length
            ? selectedLevel.includes(course.course_level_id)
            : true;

        const matchesCategory =
          selectedCategory && selectedCategory.length
            ? selectedCategory.includes(course.course_category_id)
            : true;

        const matchesBudget = selectedBudget
          ? this.checkBudgetRange(course.course_fee, selectedBudget)
          : true;

        const matchesStem = this.filters.stem_course
          ? course.stem_course === this.filters.stem_course
          : true;

        const matchesIELTS = this.filters.ielts_wavier
          ? course.ielts_wavier === this.filters.ielts_wavier
          : true;
        return (
          matchesSearch &&
          matchesLevel &&
          matchesCategory &&
          matchesBudget &&
          matchesStem &&
          matchesIELTS
        );
      });
    },

    checkBudgetRange(tuitionFee, selectedBudget) {
      const [min, max] = selectedBudget;

      if (min === null && max !== null) {
        return tuitionFee <= max;
      }

      if (min !== null && max === null) {
        return tuitionFee >= min;
      }

      if (min !== null && max !== null) {
        return tuitionFee >= min && tuitionFee <= max;
      }

      return false;
    },

    loadSavedFilters() {
      const savedFilters = JSON.parse(localStorage.getItem("search_filters"));
      if (savedFilters) {
        this.filters = { ...this.filters, ...savedFilters };
        this.applyFilters();
      }
    },
  },
  mounted() {
    const course_id = this.$route.query.course_id;

    if (course_id) {
      this.$nextTick(() => {
        setTimeout(() => {
          const id_str = "course_" + course_id;
          const el = document.getElementById(id_str);
          el.scrollIntoView();
          el.click();
          window.scrollBy(0, -100);
        }, 1000);
      });
    }
  },
  beforeMount() {
    this.loadSavedFilters();
    this.$store.dispatch("home/onGetCourseLevels");
    this.$store.dispatch("home/onGetCourseCategories");
    this.$store.dispatch("home/onGetTuitionFeesFilterValues");
    this.$store.dispatch("home/onGetTuitionUSDFeesFilterValues");
    this.setFilters();
    this.applyFilters();
  },
};
</script>
<style></style>
